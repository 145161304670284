import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { GoogleMap, LoadScript, Autocomplete, Marker, InfoWindow } from '@react-google-maps/api';
import geocodedEvents from './geocoded_events.json';
import './App.css';

const libraries = ['places'];

function App() {
  console.log('App rendering, geocodedEvents:', geocodedEvents);
  const uniqueDates = useMemo(() => [...new Set(geocodedEvents.map((event) => event.date))].sort(), []);

  const [events, setEvents] = useState([]);
  const [mapCenter] = useState({ lat: 1.292, lng: 103.8414 });
  const [selectedDates, setSelectedDates] = useState([...uniqueDates]);
  const [hotelInfo, setHotelInfo] = useState({ name: '', address: '' });
  const [hotelLocation, setHotelLocation] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapReady, setMapReady] = useState(false);
  const mapRef = useRef(null);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [hoveredEvent, setHoveredEvent] = useState(null);
  const [firstClick, setFirstClick] = useState(true);

  const updateMapBounds = useCallback(() => {
    console.log('Updating map bounds with events:', events);
    if (events.length > 0 && mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
      events.forEach((event) => {
        if (event.geocodedLocation?.lat && event.geocodedLocation?.lng) {
          bounds.extend(new window.google.maps.LatLng(event.geocodedLocation.lat, event.geocodedLocation.lng));
        }
      });
      if (hotelLocation) {
        bounds.extend(new window.google.maps.LatLng(hotelLocation.lat, hotelLocation.lng));
      }
      mapRef.current.fitBounds(bounds);
    }
  }, [events, hotelLocation]);

  const updateFilteredEvents = useCallback(() => {
    console.log('Updating filtered events, selected dates:', selectedDates);
    const filteredEvents = geocodedEvents.filter((event) => {
      const eventDate = new Date(event.date * 1000).toDateString();
      return selectedDates.some((selectedDate) => new Date(selectedDate * 1000).toDateString() === eventDate);
    });
    console.log('Filtered events:', filteredEvents);
    setEvents(filteredEvents);
  }, [selectedDates]);

  useEffect(() => {
    console.log('Initial load: setting events to all geocoded events');
    setEvents(geocodedEvents);
    setEventsLoaded(true);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (mapReady && eventsLoaded) {
      console.log('Map ready and events loaded, rendering markers');
      const newMarkers = events.map((event, index) => (
        <Marker
          key={`${event.name}-${event.date}-${index}`}
          position={{
            lat: parseFloat(event.geocodedLocation.lat),
            lng: parseFloat(event.geocodedLocation.lng),
          }}
          title={event.name}
          onMouseOver={() => handleMarkerMouseEnter(event)}
          onMouseOut={handleMarkerMouseLeave}
        />
      ));
      setMarkers(newMarkers);
      updateMapBounds();
    }
  }, [mapReady, eventsLoaded, events, updateMapBounds]);

  useEffect(() => {
    if (!loading && mapReady) {
      console.log('Events or map ready changed, updating map bounds');
      updateMapBounds();
    }
  }, [events, mapReady, updateMapBounds, loading]);

  useEffect(() => {
    if (!loading) {
      console.log('Selected dates changed, updating filtered events');
      updateFilteredEvents();
    }
  }, [selectedDates, updateFilteredEvents, loading]);

  const toggleDate = (date) => {
    console.log('Toggling date:', date);
    if (firstClick) {
      setSelectedDates([date]);
      setFirstClick(false);
    } else {
      setSelectedDates((prevDates) => {
        if (prevDates.includes(date)) {
          return prevDates.filter((d) => d !== date);
        } else {
          return [...prevDates, date];
        }
      });
    }
  };

  const toggleAllDates = () => {
    console.log('Toggling all dates');
    setSelectedDates((prevDates) => (prevDates.length === uniqueDates.length ? [] : [...uniqueDates]));
    setFirstClick(false);
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };

  const onLoad = (autocomplete) => {
    console.log('Autocomplete loaded');
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    console.log('Place changed');
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        console.log('Selected place:', place);
        setHotelLocation({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
        setHotelInfo({
          name: place.name,
          address: place.formatted_address,
        });
        updateMapBounds([
          ...events,
          { geocodedLocation: { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() } },
        ]);
      }
    }
  };

  const handleMarkerMouseEnter = (event) => {
    setHoveredEvent(event);
  };

  const handleMarkerMouseLeave = () => {
    setHoveredEvent(null);
  };

  const formatEventDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Radius of the earth in km
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d.toFixed(2);
  };

  const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
  };

  console.log('Rendering App component, events length:', events.length, 'Map ready:', mapReady);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <LoadScript googleMapsApiKey='AIzaSyCtRlX96Yk4sjGr_7si1NJaYMc6PYf2aBc' libraries={libraries}>
      <div className='App'>
        <div className='controls-overlay'>
          <div className='date-selector'>
            <div className='date-buttons'>
              <button
                onClick={toggleAllDates}
                className={selectedDates.length === uniqueDates.length ? 'selected all-button' : 'all-button'}
              >
                All
              </button>
              {uniqueDates.map((date) => (
                <button
                  key={date}
                  onClick={() => toggleDate(date)}
                  className={selectedDates.includes(date) ? 'selected' : ''}
                >
                  {formatDate(date)}
                </button>
              ))}
            </div>
          </div>
          <div className='hotel-search'>
            <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged} restrictions={{ country: 'sg' }}>
              <input
                type='text'
                placeholder='Enter hotel name or address'
                value={hotelInfo.name || hotelInfo.address}
                onChange={(e) => setHotelInfo({ name: e.target.value, address: '' })}
                className='search-bar'
              />
            </Autocomplete>
          </div>
        </div>
        <div className='map-container'>
          <GoogleMap
            mapContainerStyle={{ height: 'calc(100vh - 40px)', width: '100vw' }} // Adjust height to account for footer
            center={mapCenter}
            zoom={13}
            onLoad={(map) => {
              console.log('Map loaded');
              mapRef.current = map;
              setMapReady(true);
            }}
            options={{
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
            }}
          >
            {markers}
            {hoveredEvent && (
              <InfoWindow
                position={{
                  lat: parseFloat(hoveredEvent.geocodedLocation.lat),
                  lng: parseFloat(hoveredEvent.geocodedLocation.lng),
                }}
                onCloseClick={() => setHoveredEvent(null)}
              >
                <div className='info-window'>
                  <h3>{hoveredEvent.name}</h3>
                  <p>Date: {formatEventDate(hoveredEvent.date)}</p>
                  <p>Location: {hoveredEvent.location}</p>
                  {hotelLocation && (
                    <p>
                      Distance from hotel:{' '}
                      {calculateDistance(
                        hotelLocation.lat,
                        hotelLocation.lng,
                        parseFloat(hoveredEvent.geocodedLocation.lat),
                        parseFloat(hoveredEvent.geocodedLocation.lng)
                      )}{' '}
                      km
                    </p>
                  )}
                </div>
              </InfoWindow>
            )}
            {mapReady && hotelLocation && (
              <Marker
                position={hotelLocation}
                icon={{
                  path: window.google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  fillColor: '#0000FF',
                  fillOpacity: 1,
                  strokeWeight: 2,
                  strokeColor: '#FFFFFF',
                }}
                title='Your Hotel'
              />
            )}
          </GoogleMap>
        </div>
        <footer className='footer'>
          <div className='footer-content'>
            <p>
              Built by{' '}
              <a href='https://0xSmit.com' target='_blank' rel='noopener noreferrer'>
                0xSmit
              </a>
            </p>
          </div>
        </footer>
      </div>
    </LoadScript>
  );
}

export default App;
